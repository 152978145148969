<template>
  <v-row class="align-center justify-space-between">
    <v-col cols="10">
      <v-row class="align-center">
        <v-col cols="auto" class="grey--text">Filtro avanzado:</v-col>

        <v-col cols="auto">
          <v-chip
            v-for="(filter, i) in criteria"
            :key="i"
            close
            class="ma-2"
            small
            label
            @click:close="deleteCriteria(i)"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="18" class="mr-2" v-bind="attrs" v-on="on"> help_outline </v-icon>
              </template>
              <span>
                {{ operatorText(filter.operator) }}
                {{ getFormattedValue(filter.value) }}
              </span>
            </v-tooltip>
            {{ getFieldText(filter.field) }}
          </v-chip>
        </v-col>

        <v-col cols="auto">
          <add-filter-dialog
            :filterableHeaders="filterableHeaders"
            @addCriteria="addCriteria($event)"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="2" class="text-right">
      <v-btn
        small
        text
        color="error lighten-1"
        @click="$emit('resetOptions')"
        :disabled="criteria.length === 0 && !globalFilter"
      >
        <v-icon>delete</v-icon> Filtros
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    AddFilterDialog: () => import("@/components/crud-md/CrudMdFilterBarAdd"),
  },
  data: () => ({
    availableOperators: [
      { text: "igual a", value: "e" },
      { text: "más que", value: "gt" },
      { text: "menos que", value: "lt" },
      { text: "contiene", value: "c" },
      { text: "diferente de", value: "ne" },
      { text: "empieza por", value: "sw" },
      { text: "termina por", value: "ew" },
      { text: "entre", value: "btw" },
      //{ text: "mayor o igual que", value: "" },
      //{ text: "menor o igual que", value: "" },
    ],
  }),
  props: {
    filterableHeaders: {
      type: Array,
      default: () => [],
    },
    criteria: {
      type: Array,
      default: () => [],
    },
    globalFilter: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    addCriteria(newCriteria) {
      this.criteria.push(newCriteria);
      this.updateCriteria();
    },
    deleteCriteria(i) {
      this.criteria.splice(i, 1);
      this.updateCriteria();
    },
    updateCriteria() {
      this.$emit("updateCriteria", this.criteria);
    },
    operatorText(operator) {
      return this.availableOperators.find(o => o.value === operator).text || ":";
    },
    getFieldText(field) {
      const fieldIndex = this.filterableHeaders
        .map(h => h.filterable)
        .findIndex(h => h.field === field);
      return this.filterableHeaders[fieldIndex].text;
    },
    getFormattedValue(value) {
      return Array.isArray(value) ? value.join(", ") : value;
    },
  },
};
</script>

<style scoped></style>
